import React from 'react';
import JavaScript from '../assets/javascript-plain.svg';
import HTML from '../assets/html5-plain.svg';
import ReactImg from '../assets/react-original.svg';
// import Node from "../assets/node.png";
import Rails from '../assets/rails-plain.svg';
import CSS from '../assets/css3-plain.svg';
import Ruby from '../assets/ruby-plain.svg';
import TailWind from '../assets/tailwindcss-plain.svg';
import PostgreSQL from '../assets/postgresql-plain.svg';
import Python from '../assets/python.svg';
import Django from '../assets/django.svg';
const Skills = () => {
	return (
		<div
			name="skills"
			className="bg-[#202125] w-full text-gray-300 md:h-screen">
			{/* container */}
			<div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
				<div>
					<p className="inline text-4xl font-bold border-b-4 border-teal-600">
						SKILLS
					</p>
					<p className="py-6">
						These are some of the technologies I've worked with.
					</p>
				</div>

				<div className="grid w-full grid-cols-2 gap-4 py-8 text-center sm:grid-cols-4">
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={Ruby} alt="HTML icon" />
						<p className="my-4">Ruby</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={Rails} alt="Javascript icon" />
						<p className="my-4">Rails</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={ReactImg} alt="React icon" />
						<p className="my-4">React.js</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={PostgreSQL} alt="Rails icon" />
						<p className="my-4">PostgreSQL</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={JavaScript} alt="Css icon" />
						<p className="my-4">JavaScript</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={TailWind} alt="Ruby icon" />
						<p className="my-4">Tailwind CSS</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={HTML} alt="Tailwind icon" />
						<p className="my-4">HTML</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={CSS} alt="Postgresql icon" />
						<p className="my-4">CSS</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={Python} alt="Postgresql icon" />
						<p className="my-4">Python</p>
					</div>
					<div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 pt-4">
						<img className="w-20 mx-auto" src={Django} alt="Postgresql icon" />
						<p className="my-4">Django</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Skills;
