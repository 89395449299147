import { AiOutlineClose } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";

const WorkModal = ({ data, closeModal }) => {
  return (
    <div
      className="fixed top-0 left-0 w-[100%] h-[100vh] z-10 bg-[rgba(0,0,0,0.65)]"
      onClick={closeModal}
    >
      <div className="bg-white w-[100%] max-w-[700px]  fixed top-[50%] z-100 overflow-hidden left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-lg z-50">
        <header className="border-b-2 border-gray-800 rounded-box ">
          <div className="w-full">
            <img src={data.image} alt={data.title} />
          </div>
        </header>
        {/* contents */}

        {/* project info */}
        <div className="px-8 pt-8 pb-4">
          <h1 className="text-4xl font-extrabold text-teal-600">
            {data.title}
          </h1>
          <h4 className="text-xl font-semibold text-gray-400">
            {data.summary}
          </h4>
          <div className="pt-2">
            <hr className="" />
          </div>
        </div>
        <div className="px-8">
          <p className="text-black">{data.description}</p>
        </div>
        <footer className="flex gap-4 px-8 py-4">
          <a
            href={data.domain}
            target="_blank"
            rel="noreferrer"
            className="flex items-center px-6 py-2 my-2 text-white bg-pink-600 border-2 border-pink-600 hover:bg-white hover:border-opacity-0 hover:text-black hover:duration-300"
          >
            <span className="pr-2 align-center">
              <FiExternalLink />
            </span>
            Demo
          </a>

          <a
            href={data.github}
            target="_blank"
            rel="noreferrer"
            className="flex items-center px-6 py-2 my-2 text-white bg-pink-600 border-2 border-pink-600 hover:bg-white hover:border-opacity-0 hover:text-black hover:duration-300"
          >
            <span className="pr-2 align-center">
              <FiExternalLink />
            </span>
            Code
          </a>
          <button onClick={closeModal} className="ml-auto text-3xl">
            <AiOutlineClose />
          </button>
        </footer>
      </div>
    </div>
  );
};

export default WorkModal;
