const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="flex items-center w-full h-16 text-[#8892af] bg-[#202125]">
      <div className="mx-auto">
        <p>
          MATTIAS VELAMSSON <span className="text-pink-600">©{year}</span>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
