import React, { useState, useEffect } from "react";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const url = "https://dev.to/api/articles?username=smackdh";
    const response = await fetch(url).then((res) => res.json());
    setPosts(response);
  };

  const maxTitleLength = 27;

  return (
    <div name="blog" className="bg-[#202125] w-full md:h-screen text-gray-300">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="inline text-4xl font-bold text-gray-300 border-b-4 border-teal-600">
            BLOG
          </p>
          <p className="py-6">Read my blog and see what I'm up to!</p>
        </div>

        {/* Container */}
        <div className="grid w-full gap-4 py-8 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
          {posts &&
            posts.slice(0, 3).map((post) => {
              return (
                <a
                  className="group"
                  key={post.id}
                  href={post.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <header className="py-2">
                      <div className="">
                        <img src={post.cover_image} alt="" />
                      </div>
                    </header>
                    <div>
                      <div className="py-2 border-b-2 border-teal-600">
                        <h1 className="font-extrabold group-hover:text-teal-600 lg:text-xl md:text-lg">
                          {post.title.length > maxTitleLength
                            ? post.title.substring(0, maxTitleLength) + "..."
                            : post.title}
                        </h1>
                        <p>{post.readable_publish_date}</p>
                      </div>
                      <div className="py-2">
                        <p>{post.description}</p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Blog;
