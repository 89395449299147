// import images
import Ozei from "../assets/projects/Ozei.png";
import fff from "../assets/projects/FFF.png";
import watchList from "../assets/projects/WatchList.png";
import husmans from "../assets/projects/husmans.png";
import currencyConverter from "../assets/projects/currencyConverter.gif"

const WorkDetails = [
  {
    _id: "1",
    title: "Ozei",
    summary: "A modern restaurant reservation system for last-minute requests.",
    image: `${Ozei}`,
    role: "Backend Developer",
    techUsed: "Ruby on Rails / JS / PostgreSQL",
    domain: "https://www.ozei.fun",
    github: "https://github.com/smackdh/Ozei",
    description:
      "An instant restaurant reservation app that makes it quick and easy to make reservations on the go. With just a few taps on your smartphone, you can find available tables, and make a reservation in real-time. No more waiting on the phone or showing up to a full restaurant!",
  },
  {
    _id: "2",
    title: "FFF",
    summary: "Rent pets!",
    image: `${fff}`,
    role: "Project Lead",
    techUsed: "Ruby on Rails / JS / PostgreSQL",
    domain: "https://fluffy-friends-forever.herokuapp.com/",
    github: "https://github.com/smackdh/fluffy_friends_finder",
    description:
      "Our pet rental application is a convenient way to find and book your next furry companion. Whether you're looking for a short-term playmate or a longer-term commitment, we've got you covered.",
  },
  {
    _id: "3",
    title: "WatchList",
    summary: "Create personal movie lists, add bookmarks and notes!",
    image: `${watchList}`,
    role: "Solo Project",
    techUsed: "Ruby on Rails / JS / PostgreSQL",
    domain: "https://mattias-watchlist.herokuapp.com/lists",
    github: "https://github.com/smackdh/rails-watch-list",
    description:
      "A movie bookmarking app lets you keep track of movies you want to watch and share your thoughts with friends. ",
  },
  {
    _id: "4",
    title: "Husmans",
    summary: "Get food instantly delivered to you, like magic!",
    image: `${husmans}`,
    role: "Solo Project",
    techUsed: "React.js / JavaScript",
    domain: "https://github.com/smackdh/react-foodorder-app",
    github: "https://github.com/smackdh/react-foodorder-app",
    description:
      "A practise project using React.js with a focus on useReduce and useEffect.",
  },
    {
    _id: "5",
    title: "$ Converter",
    summary: "See the latest exchange rates and quickly convery your chosen value.",
    image: `${currencyConverter}`,
    role: "Solo Project",
    techUsed: "Ruby / Glimmer",
    domain: "https://github.com/smackdh/glimmer_app",
    github: "https://github.com/smackdh/glimmer_app",
    description:
      "A GUI built in Ruby that runs natively on Windows, macOS and Linux using Glimmer framework.",
  },
];

export default WorkDetails;
