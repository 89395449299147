import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#202125] flex justify-center items-center p-4"
    >
      <form
        className="flex flex-col max-w-[600px] w-full"
        action="https://getform.io/f/892730df-e37a-4573-a1a7-9363419199ad"
        method="POST"
      >
        <div className="pb-8">
          <p className="inline text-4xl font-bold text-gray-300 border-b-4 border-teal-600 ">
            CONTACT
          </p>
          <p className="py-4 text-gray-300">
            Have a question or want to work together?
          </p>
        </div>
        <input
          className="bg-[#ccd6f6] p-2"
          type="text"
          placeholder="Name"
          name="name"
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          className="bg-[#ccd6f6] my-4 p-2"
        />
        <textarea
          className="bg-[#ccd6f6] p-2"
          name="message"
          rows="10"
          placeholder="Message"
        ></textarea>
        <button
          className="flex items-center px-4 py-3 mx-auto my-8 text-white border-2 hover:duration-300 hover:bg-teal-600 hover:border-teal-600"
          type="submit"
        >
          Let's Collaborate
        </button>
      </form>
    </div>
  );
};

export default Contact;
