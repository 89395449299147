import React, { useState } from 'react';
import Logo from '../assets/logo1.png';
import { FaBars, FaTimes, FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';

const Navbar = () => {
	const [nav, setNav] = useState(false);
	const handleClick = () => setNav(!nav);

	return (
		<div className="z-10 fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#202125] text-gray-300">
			<div>
				<img src={Logo} alt="Logo" style={{ width: '50px' }} />
			</div>

			{/* menu */}
			<ul className="hidden md:flex">
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="home" smooth={true} duration={500}>
						Home
					</Link>
				</li>
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="about" smooth={true} duration={500}>
						About
					</Link>
				</li>
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="skills" smooth={true} duration={500}>
						Skills
					</Link>
				</li>
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="work" smooth={true} duration={500}>
						Work
					</Link>
				</li>
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="blog" smooth={true} duration={500}>
						Blog
					</Link>
				</li>
				<li className=" hover:text-pink-600 hover:scale-110">
					<Link to="contact" smooth={true} duration={500}>
						Contact
					</Link>
				</li>
			</ul>

			{/* hamburger */}
			<div onClick={handleClick} className="z-10 md:hidden">
				{!nav ? <FaBars /> : <FaTimes />}
			</div>
			{/* mobile menu */}
			<ul
				className={
					!nav
						? 'hidden'
						: 'absolute top-0 left-0 w-full h-screen bg-[#202125] flex flex-col justify-center items-center'
				}>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="home" smooth={true} duration={500}>
						Home
					</Link>
				</li>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="about" smooth={true} duration={500}>
						About
					</Link>
				</li>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="skills" smooth={true} duration={500}>
						Skills
					</Link>
				</li>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="work" smooth={true} duration={500}>
						Work
					</Link>
				</li>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="blog" smooth={true} duration={500}>
						Blog
					</Link>
				</li>
				<li className="py-6 text-4xl hover:text-pink-600">
					<Link onClick={handleClick} to="contact" smooth={true} duration={500}>
						Contact
					</Link>
				</li>
			</ul>
			{/* social icons */}
			<div className="hidden lg:flex fixed flex-col top-[35%] left-0">
				<ul>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
						<a
							className="flex items-center justify-between w-full text-gray-300"
							href="https://www.linkedin.com/in/mattias-velamsson"
							target="_blank"
							rel="noreferrer">
							LinkedIn <FaLinkedin size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
						<a
							className="flex items-center justify-between w-full text-gray-300"
							href="https://github.com/SmackDH"
							target="_blank"
							rel="noreferrer">
							Github <FaGithub size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
						<a
							className="flex items-center justify-between w-full text-gray-300"
							href="mailto:mattiaswelamsson@gmail.com"
							target="_blank"
							rel="noreferrer">
							Email <HiOutlineMail size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
						<a
							className="flex items-center justify-between w-full text-gray-300"
							href="https://drive.google.com/file/d/1fGNDBmdbjEMOf0qG9tLYk0crHt4rcmHK/view?usp=share_link"
							target="_blank"
							rel="noreferrer">
							Resume <BsFillPersonLinesFill size={30} />
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
