import React, { useState } from "react";
import WorkModal from "./WorkModal";
import workItems from "../data/WorkDetails";

const Work = () => {
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);

  //function to remove modal
  const toggleItem = (workItem = null) => {
    setData(workItem);
    setVisible(workItem !== null);
  };

  return (
    <div name="work" className="bg-[#202125] w-full md:h-screen text-gray-300">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="inline text-4xl font-bold text-gray-300 border-b-4 border-teal-600">
            PROJECTS
          </p>
          <p className="py-6">Check out some of my projects</p>
        </div>

        {/* Container */}
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Grid Item */}
          {workItems.map((workItem) => (
            <div key={workItem._id}>
              <div
                style={{ backgroundImage: `url(${workItem.image})` }}
                className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
              >
                {/* Hover effects */}
                <div className="text-center opacity-0 group-hover:opacity-100 group-hover:duration-300">
                  <h3 className="text-2xl font-bold text-center text-white ">
                    {workItem.title}
                  </h3>
                  <span className="font-thin text-pink-600 ">
                    {workItem.techUsed}
                  </span>
                  <div className="pt-8 text-center">
                    <button
                      onClick={() => toggleItem(workItem)}
                      type="button"
                      className="flex items-center px-6 py-2 mx-auto my-2 text-white bg-pink-600 border-2 border-pink-600 hover:bg-transparent hover:text-white hover:duration-300"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {visible === true && data !== null && (
            <WorkModal data={data} closeModal={() => toggleItem()} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Work;
