import React from 'react';
import mattiasImg from '../assets/avatar.png';

const About = () => {
	return (
		<div name="about" className="w-full h-screen bg-[#202125] text-gray-300">
			<div className="flex flex-col items-center justify-center w-full h-full">
				{/* Container */}
				<div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
					<div className="pl-4 text-left ">
						<p className="inline text-4xl font-bold border-b-4 border-teal-600">
							ABOUT
						</p>
					</div>
				</div>
				{/* Info Start */}
				<div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
					<div className="flex flex-col justify-center align-center">
						<div className="py-4 text-4xl font-bold sm:text-left">
							<p>
								Hi. I'm Mattias. Nice to meet you. Please take a look around.
							</p>
						</div>
						<div>
							<p>
								Full-stack Developer in Tokyo, Japan. I believe that the key to
								successful development is a combination of passion, hard work,
								and a love for problem-solving.{' '}
								<span className="text-teal-600">
									Let's work together and create something amazing!
								</span>
							</p>
						</div>
					</div>
					<div className="z-[1] mx-auto mt-10 mb-10 overflow-hidden rounded-full bg-gradient-to-b from-teal-500 w-60 h-60 md: md:h-80 md:w-80">
						<img src={mattiasImg} className="scale-150" alt="profile" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
