import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className=" w-full h-screen bg-[#202125]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-[#ccd6f6]">Hello, my name is</p>
        <h1 className="text-4xl font-bold text-pink-600 sm:text-7xl">
          Mattias Velamsson
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          I'm a Full Stack Developer.
        </h2>
        {/* <p className="text-[#8892b0] py-4 max-w-[700px]"></p> */}
        <div>
          <button className="flex items-center px-6 py-3 my-2 text-white duration-300 border-2 group hover:border-teal-600 hover:bg-teal-600">
            <Link to="work" smooth={true} duration={500}>
              View My Work
            </Link>
            <span className="duration-300 group-hover:rotate-90">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
